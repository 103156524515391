<template>
  <div class="sellPricingDetails">
      <!-- 导航栏 -->
        <van-nav-bar
            left-arrow
            fixed
           :border="false"
            safe-area-inset-top
            placeholder 
            title="订单详情"
            @click-left="leftClick"
        />
        <!--  内容 -->
        <div class="content"  v-if="sellPricingData">
           <div class="ctn">
            <div class="flex topInfo color1a">
                <div class="info">订单编号 : {{sellPricingData.oid}}</div>
                <div class="info" :style="{color:sellPricingData.status == 'UNDELIVERED'?'#FF2D2E'
                :sellPricingData.status == 'DONE' ||sellPricingData.status == 'VIOLATE'?'#02790D'
                :'#1a1a1a'}">
                  {{sellPricingData.status == "SUSPENSE"?'待审核'
                  :sellPricingData.status == "DONE" || sellPricingData.status == 'VIOLATE' ?'已完成'
                  :sellPricingData.status == "UNDELIVERED"?'待送货'
                  :sellPricingData.status == "FAIL"?'拒绝交易'
                  :sellPricingData.status =='RECEIVE'?'待处理'
                  :'已取消'}}</div>
            </div>
            <div class="flex btmInfo color9">
                <div class="time">下单时间 </div>
                <div class="info">{{sellPricingData.createTime | formatDate_}}</div>
            </div>         
            <div class="flex btmInfo color9">
                <div class="time">订单类型 </div>
                <div class="marginRight">{{sellPricingData.goods[0].name}}  </div>
                <div class="icon">卖料</div> 
            </div>          
            <div class="flex btmInfo color9">
                <div class="time">单价  (元/克) </div>
                <div class="color6">{{sellPricingData.goods[0].price.toFixed(2)}}</div>
            </div>          
            <div class="flex btmInfo color9">
                <div class="time">定价重量 (克) </div>
                <div class="color6">{{sellPricingData.goods[0].weight}}</div>
            </div>          
            <div class="flex btmInfo color9">
                <div class="time">押金 (元) </div>
                <div class="color6">{{sellPricingData.earnestMoney.toFixed(2)}} <van-icon name="arrow"  @click="openShowPop(sellPricingData.oid,sellPricingData)" v-if="sellPricingData.status =='UNDELIVERED'" /> </div>
            </div>          
            <div class="flex btmInfo color9 ">
                <div class="time">保管费 (元)</div>
                <div class="color6">{{sellPricingData.storageFee}}</div>
            </div>

           </div> 
           <div class="settlement" v-if="sellPricingData.openId">
                <div class="leftText">结算单</div>
                <div class="xiangqing" @click="toDetails(sellPricingData)">详情 <div class="icon"></div></div>
           </div>
            <!-- <div v-for="(item) in sellPricingData.referenceInfoList" :key="item.openId">
                <div class="btmBorder"   v-if="sellPricingData.status == 'UNDELIVERED'?true:sellPricingData.status =='DONE'?true:false">
                    <div class="flex btmInfo color9" >
                        <div class="time">结算时间 :</div>
                        <div>{{item.time | formatDate_}}</div>
                    </div> 
                    <div class="flex btmInfo color9 " >
                        <div class="time">结算重量(g)</div>
                        <div>{{item.referenceWeight}}</div>
                    </div>
                    <div class="flex btmInfo color9 " >
                        <div class="time"></div>
                        <div @click="orderJieSuan(item)">详情 ></div>
                    </div>             
                </div>
            </div> -->
        </div>
        <!-- 定金弹窗 -->
        <showPop ref="pop"  :showPop.sync="showPop"  />
        <footer class="foot" v-if="isbreak" @click="breakContract">无法交付现货怎么办？</footer>
  </div>
</template>

<script>
import showPop from '@/components/depositAdjust.vue'
import Stomp from 'stompjs'
import *as api from'@/utils/api'
export default {
    components:{
        showPop,
    },
    data() {
        return {
            sellPricingData:{},
            orderid:"",//订单id
            showPop:false,
                client:{},
                // 所有商品数据
                allpro:[],
                // 首次加载的数据
                initDatalist: [],
                // 提交时间
                postTime:'',
                firstloadData: true,
                difference:'',//物料差价
                differenceData:'',
                isbreak:false,
                pricehj:0,
                priceboj:0,
                pricebaj:0,
                pricebaiy:0,
                initCity:{
                    huangjin:'huangjin9999',
                    baiyin:'baiyin9999',
                    bajin:"bajin9996",
                    bojin:"bojin9996"
                },
                isClick:false,
                
            
        }
    },
    watch:{
          allpro:function(){
               if(this.initCity != ''){
                  this.updatearr(this.initCity)
                  if (this.sellPricingData.goods[0]) {
                    
                  if (this.sellPricingData.goods[0].name == '黄金实物') {
                    // 判断 定价 - 现价 <= 0 需补押金  否则不需补
                        this.sellPricingData.goods[0].price - this.pricehj<=0?this.isClick=true:this.isClick=false
                    //  差价  定价 - 现价*重量
                        this.difference = (( this.sellPricingData.goods[0].price - this.pricehj)*this.sellPricingData.weight).toFixed(2)

                  }else if (this.sellPricingData.goods[0].name == '白银实物') {
                        this.sellPricingData.goods[0].price - this.pricebaiy<=0?this.isClick=true:this.isClick=false
                        this.difference = (( this.sellPricingData.goods[0].price - this.pricebaiy)*this.sellPricingData.weight).toFixed(2)

                  }else if (this.sellPricingData.goods[0].name == '铂金实物') {
                        this.sellPricingData.goods[0].price - this.priceboj<=0?this.isClick=true:this.isClick=false
                        this.difference = (( this.sellPricingData.goods[0].price - this.priceboj)*this.sellPricingData.weight).toFixed(2)

                  }else if (this.sellPricingData.goods[0].name == '钯金实物') {
                        this.sellPricingData.goods[0].price - this.pricebaj<=0?this.isClick=true:this.isClick=false
                        this.difference = (( this.sellPricingData.goods[0].price - this.pricebaj)*this.sellPricingData.weight).toFixed(2)

                  }
                }else{
                  console.log('111');
                }
              }

              }
  },
async  mounted() {
          const orderId = this.$route.query.id
          try{
             let res = await api.getsellgold(orderId)
              console.log('res',res);
             this.sellPricingData = res
              if (res.businessType != '0' &&  res.status == "UNDELIVERED" ) {
                this.isbreak = true
              }
          }catch{  
          }
    },
    methods:{
      // 详情按钮事件
    toDetails(item){
            if (item.businessType == '1') {
                  // 跳转违约结算
                this.$router.push({
                  name:'default',
                  query:{orderid:item.openId}
                })
            }else{
                this.$router.push({
                  name:'selldetails',
                  query:{orderid:item.openId}
                })
            }

    },
        // 定金按钮事件
    openShowPop(id,sellPricingData){
        const userInfo = JSON.parse(localStorage.getItem('userinfo'))
          //userInfo.depositDetail.curSell.policy  政策押金
          // 差价<0 判断 needDeposit >= 0 不补 <0 补
        const needDeposit = +sellPricingData.earnestMoney - +(+sellPricingData.weight * userInfo.depositDetail.curSell.policy)  + +this.difference
 
      if (!this.isClick) {
          this.$toast('暂无需补押金')
          return false
      }
      if( this.difference<0 && needDeposit>=0){
                
          return this.$toast('暂无需补押金'),false
      }
      // 只有状态为UNDELIVERED需补押金
      if (this.sellPricingData.status =="UNDELIVERED" ) {
        this.$refs.pop.openDialog(id,sellPricingData,'SELL')
      }

    },
    // 无法交付现货按钮事件
    breakContract(){
      let item = this.sellPricingData
        if (item.businessType != '0' &&  item.status == "UNDELIVERED" ) {
            this.$router.push({
                name:'breakContract'
            })
        }
    
    },
    orderJieSuan(item){
            this.$router.push({
                name:"selldetails",
                query:{
                    orderid:item.openId,
                    payType:this.sellPricingData.status == 'DONE'?'2':'0'
                    }
            })
    },
    // 顶部返回按钮
    leftClick(){
            this.$router.push({
                name:'sellSettlement'
            })
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      console.log('1');
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
       this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      console.log('1');
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      if (!this.client.connected) {
        setTimeout(() => {
          console.log('11');
          this.connect();
        }, 1000);
      }
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
                //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    // 获取接口数据
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time
      // console.log('postTime1',this.postTime);
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
    },
    // 价格变动更新视图
    pricenew(obj){
      this.allpro.find((x)=>{
        if(x[obj.huangjin]){
          this.pricehj = x[obj.huangjin]['huigou']
        }        
        if(x[obj.baiyin]){
          this.pricebaiy = x[obj.baiyin]['huigou']
        }        
        if(x[obj.bajin]){
          this.pricebaj = x[obj.bajin]['huigou']
        }        
        if(x[obj.bojin]){
          this.priceboj = x[obj.bojin]['huigou']
        }
      })
    },
    // 更新数组
    updatearr(obj){
            this.pricenew(obj)
    },
    },
async   created(){
        this.connect()
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 80px;
        .van-nav-bar__content{
        line-height: 80px;
        height: 80px;
        .van-nav-bar__title{
            font-size: 34px;
        }
        .van-icon{
            font-size: 36px;
            color: #1a1a1a;
        }
        }
     }
}
.sellPricingDetails{
    height: 100%;
    background-color: #f5f5f5;
    .content{
        margin-top: 40px;
        width: 100%;
        padding: 20px 20px;
        .ctn{
            width: 100%;
            border-radius: 8px;
            background-color: #fff;
            padding: 20px 0 40px;
        }
    }
    .topInfo{
        border-bottom: 1px solid #e9e9e9;
        padding: 10px  30px  20px ;
        position: relative;
        .iconMai{
            width: 50px;
            height: 50px;
            color: #fff;
            position: absolute;
            top: 0;
            left: 30px;
            font-size: 26px;
            text-align: center;
            line-height: 50px;
            border-radius: 50%;
            background-color: #fe462a;
        }
    }
    .btmInfo{
        padding: 20px 30px 0 30px;
        position: relative;
    }
}
.flex{
    display: flex;
    justify-content: space-between;

    div{
        letter-spacing:2px;
        font-size: 28px;
    }
    .info{
        letter-spacing:0px;

    }
}
.foot{
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin-left: -90px;
    color: #999999;
    font-size: 24px;
}
.color1a{
    color: #1a1a1a;
}
.color9{
    color: #999999;
}
.colorffb{
    color: #ffb200;
}
.color6{
    color: #666666;
    .van-icon{
        top: 4px;
    }
}
.btmBorder{
    margin-top: 20px !important;
    border-top: 1px solid #666666;
}
.marginRight{
    margin-right: 66px;
}
.icon{
    position: absolute;
    top: 13px;
    right: 30px;
    width: 60px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 22px !important;
    background-color:#CC0E0F ;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    font-weight: 300;
}
.settlement{
  width: 100%;
  height: 80px;
  background-color: #fff;
  margin-top: 30px;
  border-radius: 8px;
  color: #212121;
  line-height: 80px;
  padding: 0px 40px;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
}
.xiangqing{
      width: 100px;
     .icon{
       width: 30px;
       height: 30px;
       background: url('~@/assets/images/btn_sell_moreHei@2x.png') no-repeat;
       background-size: 100%;
       position: relative;
       top: 4px;
       right: 0;
   }  
}
</style>